import React from "react";
import main from '../screens/images/image-2000x300 (1).jpg'
const Home = () => {
  return (
    <>
      <div className="hero border-1 pb-3">
        <div className="card bg-dark text-white border-0 mx-3">
          <img
            className=" w-full"
            src={main}
            alt="Card"
            height={5000}
          />
          <div className="card-img-overlay d-flex align-items-center ">
            <div className="container hidden " style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
              <h5 className="card-title fs-1 text">New Season Arrivals</h5>
              <p className="card-text fs-5 d-none d-sm-block ">
                This is a wider card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
