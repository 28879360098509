import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Lottie from 'react-lottie-player';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Ecom from '../images/ecom2.png'
import Ecom1 from '../images/ecom983 1.png'
import Polygon from '../images/Polygon.png';
import Glassdoor from '../images/glassdoor1.png';
import Wave from '../images/radio-animation.json';
import Chart from '../images/chart_animation.json';
// import './landing.css'
import Nav from '../nav/nav';
import Graph from '../graph/graph';
import { useNavigate } from 'react-router-dom';



const Landing = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <div className=' h-screen overflow-x-hidden overflow-y-auto'>
            <section className='h-full  flex-col'>
                <Nav />
                {/* <div className=' flex flex-row justify-between p-10 hidden md:flex'>
                    <div className='hidden sm:flex'>
                        <img src={Polygon} alt="Polygon" />
                    </div>
                    <div className='flex flex-row gap-5 mt-1 '>
                        <div>Solutions</div>
                        <div>Pricing</div>
                        <div>Support</div>
                    </div>
                    <div className='flex flex-row gap-5 mr-[20] md:flex'>
                        <div><Button variant="text" color="inherit" style={{ textTransform: "none" }}>Login</Button></div>
                        <div><Button variant="contained" style={{ borderRadius: 20, backgroundColor: "#F5F4FD", color: "#6E63A5", textTransform: "none" }}>Signup</Button></div>
                    </div>
                </div> */}
                <Graph />

                <div className='block md:hidden'>

                    <div className='flex flex-col justify-center items-center'>
                        <div className='relative w-[100%] md:w-[700px]'>
                            <div className='relative h-[400px] md:h-[800px] w-full'>
                                <Lottie loop animationData={Wave} play />
                            </div>

                            <div className='absolute top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4 w-[330px] text-center text-4xl mt-[-20px]'>
                                Create the software your business needs
                            </div>

                            <div className='absolute top-[60%] left-2/4 transform -translate-x-2/4 w-[400px] text-center mb-50'>
                                Discover a platform that enables you to design, develop and manage your web site exactly the way you want.
                            </div>
                            <div className=' mt-[-30%] flex align-center justify-center'>
                                <TextField
                                    style={{ width: '350px' }}
                                    label=""
                                    InputProps={{
                                        style: { borderRadius: '20px' },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button variant="outlined" style={{ backgroundColor: "#7E72BC", color: "#FFFFFF", textTransform: "none" }}>Start free trial</Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                        </div>
                        <div className='flex-1' style={{
                            backgroundColor: 'white',
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                            borderRadius: '2rem',
                            border: 'none',  // Remove border
                            width: '90%',
                            marginTop: '80px'
                        }}>
                            <Lottie loop animationData={Chart} play />
                        </div>

                        <div className=' w-[388px] h-[382px] mt-10'>
                            <img src={Glassdoor} alt="Logo" />
                        </div>
                        <div>
                            MAXIMIZE YOU BUSINESS GROWTH ONLINE
                        </div>
                        <div className='w-[400px] h-[400px] flex'>
                            <img src={Ecom} alt='' className='h-full w-full flex' />
                        </div>
                        <div>SHOWCASE YOUR BUSINESS</div>
                        <div className='flex h-[350px] w-[400px]'>
                            <img src={Ecom1} alt='' />
                        </div>
                        <div></div>
                    </div>
                </div>
            </section>

            <div className='bg-[#D5D5D7] h-[820px] w-full flex hidden md:flex'>
                <div className='flex-1 justify-center flex items-center'>
                    <div className='w-[574px] h-[490px] flex'>
                        <img src={Ecom} alt='' className='h-full w-full flex' />
                    </div>
                </div>
                <div className='flex-1 justify-center flex items-center flex-col gap-6 mt-20'>
                    <div className='w-[572px] h-[100px]  text-[36px] text-white'>
                        MAXIMIZE YOU BUSINESS GROWTH ONLINE
                    </div>
                    <div>
                        <div className='w-[572px] h-[100px] text-[16px]'>
                            Explore a wide range of industry-leading website templates, designer fonts, and color palettes to discover the ideal match for your unique style and professional necessities.
                        </div>
                        <div className='w-[572px] h-[100px] text-[16px]'>
                            Delve into the options available for adding the tools you desire, whether it involves setting up an online store, booking services, or incorporating your favorite third-party extensions.
                        </div>
                        <div className='w-[572px] h-[100px] text-[16px]'>
                            Capture attention across every inbox and social feed. Utilize on-brand email campaigns and social tools, simplifying customer retention and expanding your audience.
                        </div>
                        <div className='text-[16px] text-black flex items-end justify-end w-[572px] h-[100px]'>Get Started</div>
                    </div>
                </div>

            </div>
            <div className='h-[820px] w-full flex flex-col hidden md:flex'>
                <div className='flex-1'>
                    <div className='w-[877px] text-[56px] p-16'>Unleash boundless potential with a single platform.</div>
                </div>
                <div className=' flex-1'>
                    <div className='flex flex-row gap-24 p-16'>
                        <div className='flex justify-evenly flex-col gap-5'>
                            <div className='w-[330px] h-[2px] bg-black'></div>
                            <div className='text[16px]'>Highly capable infrastructure.</div>
                            <div className='text-[14px] w-[320px]'>Acquire a robust enterprise-grade foundation, meticulously designed to accommodate your limitless scalability needs and provide you with peace of mind.</div>
                        </div>
                        <div className='flex justify-evenly flex-col gap-5'>
                            <div className='w-[330px] h-[2px] bg-black'></div>
                            <div className='text[16px]'>Highly capable infrastructure.</div>
                            <div className='text-[14px] w-[320px]'>Acquire a robust enterprise-grade foundation, meticulously designed to accommodate your limitless scalability needs and provide you with peace of mind.</div>
                        </div>
                        <div className='flex justify-evenly flex-col gap-5'>
                            <div className='w-[330px] h-[2px] bg-black'></div>
                            <div className='text[16px]'>Highly capable infrastructure.</div>
                            <div className='text-[14px] w-[320px]'>Acquire a robust enterprise-grade foundation, meticulously designed to accommodate your limitless scalability needs and provide you with peace of mind.</div>
                        </div>
                    </div>
                </div>
                <div className='flex-1'>
                    <div className='flex p-16'>
                        <Button variant="contained" style={{ borderRadius: 20, backgroundColor: "#7E72BC", color: "#FFFFFF", textTransform: "none", height: '48px', width: '167px' }}>Get Started</Button>
                    </div>
                </div>
            </div>
            <div className='bg-black h-[1500px] flex flex-col gap-52 hidden md:flex'>
                <div className=' h-[400px]'>
                    <div className='text-[100px] text-white flex justify-center w-full'>SHOWCASE YOUR BUSINESS</div>
                    <div className='w-[796px] h-[273px] text-[80px] text-white p-14 '>Website templates for every purpose</div>
                    <div className='w-[596px] h-[200px] text-[20px] text-white p-14'>Begin with a versatile template and personalize it to match your style and professional requirements using our website builder.</div>
                </div>
                <div className='flex-1  flex flex-row gap-[500px]'>
                    <div className='flex flex-col gap-10 p-12'>
                        <div className='text-[36px] text-white'>Arts & Crafts</div>
                        <div className='text-[36px] text-white'>Tools</div>
                        <div className='text-[36px] text-white'>Motorbikes</div>
                        <div className='text-[36px] text-white'>Hardwares</div>
                        <div className='text-[36px] text-white'>Clothing</div>
                    </div>
                    <div className='flex h-[670px] w-[954px]'>
                        <img src={Ecom1} alt='' />
                    </div>
                </div>
            </div>
            <div className='h-[400px] flex  flex-row hidden md:flex'>
                <div className='flex-1 flex justify-center'>
                    <div className='flex flex-col h-[144px] w-[340px] gap-5'>
                        <div className='flex h-80 w-80'>
                            <img src={Polygon} alt='' />
                        </div>
                        <p>With the Wix website builder, you get an all-inclusive solution encompassing enterprise-grade infrastructure, business features, advanced SEO, and marketing tools, empowering everyone to create and thrive online.</p>
                    </div>
                </div>
                <div className='flex-1 flex flex-col gap-5'>
                    <div className='text-[22px]'>Solutions</div>
                    <div>
                        <div>Online Store</div>
                        <div>Online Booking</div>
                        <div>Arts & Crafts Website</div>
                        <div>Clothing Website</div>
                        <div>Tools Website</div>
                        <div>Motorbikes Website</div>
                        <div>Hardware Website</div>
                    </div>
                </div>
                <div className='flex-1 flex flex-col gap-5'>
                    <div className='text-[22px]'>Support</div>
                    <div>
                        <div>Website Builder</div>
                        <div>Website Templates</div>
                        <div>Website Design</div>
                        <div>Web Hosting</div>
                        <div>Mobile App Builder</div>
                        <div>Website Accessiblity</div>
                    </div>
                </div>
                <div className='flex-1 flex flex-col gap-5'>
                    <div className='text-[22px]'>Solutions</div>
                    <div>
                        <div>Online Store</div>
                        <div>Online Booking</div>
                        <div>Arts & Crafts Website</div>
                        <div>Clothing Website</div>
                        <div>Tools Website</div>
                        <div>Motorbikes Website</div>
                        <div>Hardware Website</div>
                    </div>
                </div>
                <div className='flex-1 flex flex-col gap-5'>
                    <div className='text-[22px]'>Solutions</div>
                    <div>
                        <div>Online Store</div>
                        <div>Online Booking</div>
                        <div>Arts & Crafts Website</div>
                        <div>Clothing Website</div>
                        <div>Tools Website</div>
                        <div>Motorbikes Website</div>
                        <div>Hardware Website</div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Landing;
