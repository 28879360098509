import React from "react";
import Foote from "../screens/footer/footer";
const Footer = () => {
  return (
    <>
      <Foote />
    </>
  );
};

export default Footer;
