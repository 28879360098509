import React, { useContext } from 'react';
import './signup.css';  // Import the CSS file for this component
import Lottie from 'react-lottie-player';
import webSite from '../images/website-building-of-shopping-sale.json'
import LockIcon from '@mui/icons-material/Lock';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../home/DataContext';


const Signup = () => {
  const { setResponseData } = useContext(DataContext);
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignup = async () => {
    const firstName = document.getElementById('firstName').value;
    const lastName = document.getElementById('lastName').value;
    const businessType = document.getElementById('businessName').value;
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;

    // Check if any required fields are empty
    if (!firstName || !lastName || !businessType || !email || !password) {
      alert('Please fill all details.');
      return; // Stop the function if any field is empty
    }

    const formData = {
      firstName,
      lastName,
      businessType,
      email,
      password
    };

    try {
      const response = await fetch('https://us-central1-graphical-petal-399811.cloudfunctions.net/authFunction/merchantRegister', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const responseData = await response.json(); // Read response as JSON once

      if (response.ok) {
        if (responseData.status === 400 && responseData.statusMsg === 'Email is already registered') {
          alert('Email is already registered. Please use a different email.');
        } else {
          setResponseData(responseData);
          localStorage.setItem('userData', JSON.stringify(responseData));
          navigate('/Home');
        }
      } else {
        console.error('Signup failed:', responseData);
      }
    } catch (error) {
      console.error('An error occurred during signup:', error);
    }
  };

  return (
    <div className="main-container">
      <div className="image1 hidden md:flex">
        <Lottie loop animationData={webSite} play />
      </div>
      <div className="image2">
        <div className="inputfields">
          <div className="lockicon" >
            <LockIcon style={{ color: "6E63A5" }} />
          </div>
          <div className='text-large mt-10'>Create an account</div>
          <div className='text-small'>Let's get started with your 30 days free trial</div>
          <div className="namefield1 flex-column md:flex">
            <TextField id="firstName" label="FirstName" variant="outlined" type='firstName' className='business' />
            <TextField id="lastName" label="LastName" variant="outlined" type='lastName' className='business' />
          </div>
          <div className='businessName'>
            <TextField id="businessName" label="BusinessName" variant="outlined" className='business' type='businessName' />
            <TextField id="businessName" label="Email" variant="outlined" className='business' type='email' />
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Button variant="contained" className='signup' style={{ backgroundColor: '#6E63A5' }} onClick={handleSignup}>Signup</Button>
            <div className='SignIn' onClick={() => { navigate("/Login") }}>Already have an account? Sign in</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
