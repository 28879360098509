import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
const HomeNav = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();
    const response = JSON.parse(localStorage.getItem('userData'));
    let firstName, businessName;

    if (response && response.payload) {
        firstName = response.payload.firstName;
        businessName = response.payload.businessType;
    } else {
        // Handle the case where response or response.payload is null
        firstName = '';
        businessName = '';
    }

    const handleClearUserData = () => {
        // Clear 'userData' from local storage
        localStorage.removeItem('userData');

        // Navigate to the landing page
        navigate('/Landing');
    };

    return (
        <nav style={{ backgroundColor: '#7A9A95' }}>
            <ul>
                <li>
                    <a href="#" style={{ backgroundColor: '#7A9A95' }}>☰</a>
                    <ul className="dropdown">
                        <li><a onClick={() => { navigate('/Home') }}>Home</a></li>
                        <li><a onClick={() => { navigate('/product') }}>Products</a></li>
                        <li><a onClick={() => { navigate('/orders') }}>Orders</a></li>
                        <li><a onClick={() => { navigate('/SettingsPage') }}>Settings</a></li>
                    </ul>
                </li>
            </ul>

            <h4>{businessName}</h4>

            <div className="user-info">
                <div>
                    <Avatar
                        alt="Travis Howard" src="/static/images/avatar/2.jpg"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                    </Avatar>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={handleClearUserData}>Logout</MenuItem>
                    </Menu>
                </div>
                <span>Welcome, {firstName}</span>
            </div>
        </nav>
    )
}

export default HomeNav
