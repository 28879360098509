import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Home, Bookmark, User } from "react-feather";
import Button from '@mui/material/Button';
import Polygon from '../images/Polygon.png';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LoginIcon from '@mui/icons-material/Login';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import Tooltip from '@mui/material/Tooltip';

function Nav() {
    const [windowDimension, setWindowDimension] = useState(null);
    const navigate = useNavigate()
    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;

    return (
        <Styles.Wrapper>
            <CSSReset />

            {isMobile ? (
                <MobileNavbar.Wrapper>
                    <MobileNavbar.Items>
                        <MobileNavbar.Item>
                            <Tooltip title="Solutions" placement="top">
                                <MobileNavbar.Icon>
                                    <EmojiObjectsIcon style={{ color: "#6E63A5", fontSize: '30px' }} />
                                </MobileNavbar.Icon>
                                Solutions
                            </Tooltip>
                        </MobileNavbar.Item>
                        <MobileNavbar.Item>
                            <Tooltip title="Pricing" placement="top">
                                <MobileNavbar.Icon>
                                    <CurrencyPoundIcon style={{ color: "#6E63A5", fontSize: '30px' }} />
                                </MobileNavbar.Icon>
                                Pricing
                            </Tooltip>
                        </MobileNavbar.Item>
                        <MobileNavbar.Item>
                            <Tooltip title="Support" placement="top">
                                <MobileNavbar.Icon>
                                    <SupportAgentIcon style={{ color: "#6E63A5", fontSize: '30px' }} />
                                </MobileNavbar.Icon>
                                Support
                            </Tooltip>
                        </MobileNavbar.Item>
                        <MobileNavbar.Item>
                            <Tooltip title="Login" placement="top">
                                <MobileNavbar.Icon>
                                    <LoginIcon style={{ color: "#6E63A5", fontSize: '30px' }} onClick={() => { navigate('/Login') }} />
                                </MobileNavbar.Icon>
                                Login
                            </Tooltip>
                        </MobileNavbar.Item>
                        <MobileNavbar.Item>
                            <Tooltip title="Signup" placement="top">
                                <MobileNavbar.Icon>
                                    <AppRegistrationIcon style={{ color: "#6E63A5", fontSize: '30px' }} onClick={() => navigate('/Signup')} />
                                </MobileNavbar.Icon>
                                Signup
                            </Tooltip>
                        </MobileNavbar.Item>
                    </MobileNavbar.Items>
                </MobileNavbar.Wrapper>
            ) : (
                <Navbar.Wrapper>
                    <Navbar.Logo>
                        <img src={Polygon} alt="Polygon" />
                    </Navbar.Logo>
                    <Navbar.Items>
                        <Navbar.Item style={{ fontSize: '18px', color: "#6E63A5" }}>Solutions</Navbar.Item>
                        <Navbar.Item style={{ fontSize: '18px', color: "#6E63A5" }}>Pricing</Navbar.Item>
                        <Navbar.Item style={{ fontSize: '18px', color: "#6E63A5" }}>Support</Navbar.Item>
                    </Navbar.Items>
                    <Navbar.Items>
                        <Navbar.Item><Button variant="text" color="inherit" style={{ textTransform: "none", fontSize: '14px', color: "#6E63A5" }} onClick={() => { navigate('/Login') }}>Login</Button></Navbar.Item>
                        <Navbar.Item><Button variant="contained" style={{ borderRadius: 20, backgroundColor: "#F5F4FD", color: "#6E63A5", textTransform: "none", fontSize: '14px' }} onClick={() => navigate('/Signup')}>Signup</Button></Navbar.Item>
                    </Navbar.Items>
                </Navbar.Wrapper>
            )}
        </Styles.Wrapper>
    );
}

const Styles = {
    Wrapper: styled.main`
    display: flex;
    background-color: #eeeeee;
  `
};

const Navbar = {
    Wrapper: styled.nav`
    flex: 1;

    align-self: flex-start;

    padding: 1rem 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;
  `,
    Logo: styled.h1`
    border: 0px solid gray;
    padding: 0.5rem 1rem;
  `,
    Items: styled.ul`
    display: flex;
    list-style: none;
  `,
    Item: styled.li`
    padding:  1rem;
    margin:1.2rem;
    cursor: pointer;
  `
};

const MobileNavbar = {
    Wrapper: styled(Navbar.Wrapper)`
    position: fixed;
    width: 100vw;
    bottom: 0;

    justify-content: center;
  `,
    Items: styled(Navbar.Items)`
  flex: 1;
  padding: 0;
  flex-direction: row; /* Adjusted to display items in a column */
  align-items: center;

  justify-content: space-around;
`,

    Item: styled(Navbar.Item)`
  padding: 0.8rem; /* Adjusted padding */
  margin: 0;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 1.2rem;
`,
    Icon: styled.span``
};

const CSSReset = createGlobalStyle`
  *,
  *::before, 
  *::after {
    margin: 0; 
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; /*1rem = 10px*/
    box-sizing: border-box;      
  }  

  body {
    font-size: 1.4rem;
    font-family: sans-serif;
  }
`;

export default Nav;
