import handleCart from './handleCart';
import authReducer from './authReducer';
import handleWishlist from './handleWhishlist';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    handleCart,
    authReducer, // Use a different key name, not 'authReducer'
    handleWishlist
});

export default rootReducer;
