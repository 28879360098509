import React, { useState, useContext } from 'react';
import './Home.css'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ReviewsIcon from '@mui/icons-material/Reviews';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Rating from '@mui/material/Rating';
import { useSearchParams } from 'react-router-dom';
import { DataContext } from './DataContext';
import { useNavigate } from 'react-router-dom';
import HomeNav from '../homeNav/homeNav';
import Link from '@mui/material/Link';
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Grid,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    Typography
} from '@mui/material';

// project import
import OrdersTable from './OrdersTable';
import IncomeAreaChart from './IncomeAreaChart';
import MonthlyBarChart from './MonthlyBarChart';
import ReportAreaChart from './ReportAreaChart';
import SalesColumnChart from './SalesColumnChart';
import MainCard from '../../components/MainCard';
import AnalyticEcommerce from '../../components/cards/statistics/AnalyticEcommerce';

// assets
import { GiftOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons';
import avatar1 from '../../assets/images/users/avatar-1.png';
import avatar2 from '../../assets/images/users/avatar-2.png';
import avatar3 from '../../assets/images/users/avatar-3.png';
import avatar4 from '../../assets/images/users/avatar-4.png';

// avatar style
const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem'
};

// action style
const actionSX = {
    mt: 0.75,
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',
    transform: 'none'
};

// sales report status
const status = [
    {
        value: 'today',
        label: 'Today'
    },
    {
        value: 'month',
        label: 'This Month'
    },
    {
        value: 'year',
        label: 'This Year'
    }
];


const Home = (props) => {
    const navigate = useNavigate()
    const lottieUrl = 'https://lottie.host/6e723f72-8418-48b9-b0a1-751941b2fdd0/xtHXfjAB28.json';
    const generateRandomData = (count) => {
        const data = [];
        let totalRevenue = 5000;

        for (let i = 30 - count; i < 30; i++) {
            totalRevenue += Math.floor(Math.random() * 1000) - 500;
            data.push({
                name: `Day ${i + 1}`,
                revenue: totalRevenue >= 0 ? totalRevenue : 0,
            });
        }

        return data;
    };

    const revenueData = generateRandomData(30);
    // const [value, setValue] = React.useState(2);
    const [value, setValue] = useState('today');
    const [slot, setSlot] = useState('week');
    const { responseData } = useContext(DataContext);

    const response = JSON.parse(localStorage.getItem('userData'));
    console.log(response.payload)

    let firstName, businessName, merchantId;

    if (response && response.payload) {
        firstName = response.payload.firstName;
        businessName = response.payload.businessType;
        merchantId = response.payload.merchantId
    } else {
        // Handle the case where response or response.payload is null
        firstName = '';
        businessName = '';
        merchantId = '';
    }

    const handleClearUserData = () => {
        // Clear 'userData' from local storage
        localStorage.removeItem('userData');

        // Navigate to the landing page
        navigate('/Landing');
    };
    const handleNavigation = () => {
        navigate(`/customerHome/${merchantId}`, { state: { data: response.payload } });
    };



    return (
        <div>
            <HomeNav />
            <div className='store'>
                <p>This is some content.</p>
                <Link
                    component="button"
                    variant="body2"
                    onClick={handleNavigation}
                >
                    {businessName}
                </Link>
            </div>
            <Grid container rowSpacing={4} columnSpacing={1} sx={{ margin: 0.5, width: '98%' }}>
                {/* row 1 */}
                <Grid xs={12} sx={{ mb: -2.25 }}>
                    <Typography variant="h5">{businessName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <AnalyticEcommerce title="All Time Sales" count="4,42,236" percentage={59.3} extra="35,000" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <AnalyticEcommerce title="Total Users" count="78,250" percentage={70.5} extra="8,900" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <AnalyticEcommerce title="Total Order" count="18,800" percentage={27.4} isLoss color="warning" extra="1,943" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <AnalyticEcommerce title="This Month Sales" count="$35,078" percentage={27.4} isLoss color="warning" extra="$20,395" />
                </Grid>

                <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

                {/* row 2 */}
                <Grid item xs={12} md={7} lg={12}>
                    <Grid container >
                        <Grid item>
                            <Typography variant="h5">Unique Visitor</Typography>
                        </Grid>
                        <Grid item>
                            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Button
                                    size="small"
                                    onClick={() => setSlot('month')}
                                    color={slot === 'month' ? 'primary' : 'secondary'}
                                    variant={slot === 'month' ? 'outlined' : 'text'}
                                >
                                    Month
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => setSlot('week')}
                                    color={slot === 'week' ? 'primary' : 'secondary'}
                                    variant={slot === 'week' ? 'outlined' : 'text'}
                                >
                                    Week
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <MainCard content={false} sx={{ mt: 1.5, width: "100%" }}>
                        <Box sx={{ pt: 1, pr: 2 }}>
                            <IncomeAreaChart slot={slot} />
                        </Box>
                    </MainCard>
                </Grid>
                {/* row 3 */}
                <Grid item xs={12} md={7} lg={12}>
                    <Grid container >
                        <Grid item>
                            <Typography variant="h5">Recent Orders</Typography>
                        </Grid>
                        <Grid item />
                    </Grid>
                    <MainCard sx={{ mt: 2 }} content={false}>
                        <OrdersTable />
                    </MainCard>
                </Grid>

                {/* row 4 */}
                <Grid item xs={12} md={7} lg={12}>
                    <Grid container>
                        <Grid item>
                            <Typography variant="h5">Sales Report</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="standard-select-currency"
                                size="small"
                                select
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                sx={{ '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
                            >
                                {status.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <MainCard sx={{ mt: 1.75 }}>
                        <Stack spacing={1.5} sx={{ mb: -12 }}>
                            <Typography variant="h6" color="secondary">
                                Net Profit
                            </Typography>
                            <Typography variant="h4">$1560</Typography>
                        </Stack>
                        <SalesColumnChart />
                    </MainCard>
                </Grid>

            </Grid>
        </div>
    )
}

export default Home
