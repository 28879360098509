import React from 'react'
import { Footer, Navbar } from "../components"
import AllProducts from '../components/AllProducts'
import { useParams } from 'react-router-dom'

const Products = () => {
  const { merchantId } = useParams();
  return (
    <>
      <Navbar merchantId={merchantId} />
      <AllProducts merchantId={merchantId} />
      <Footer merchantId={merchantId} />
    </>
  )
}

export default Products