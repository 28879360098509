// For Add Item to Cart
export const addCart = (product) => {
    return {
        type: "ADD_TO_CART",
        payload: product
    }
}

// For Delete Item to Cart
export const delCart = (product) => {
    return {
        type: "REMOVE_FROM_CART",
        payload: product
    }
}
export const setAuthenticated = (status) => ({
    type: 'SET_AUTHENTICATED',
    payload: status,
});

export const addWishlist = (product) => {
    return {
        type: "ADD_TO_WISHLIST",
        payload: product
    }
}

export const delWishlist = (product) => {
    return {
        type: "REMOVE_FROM_WISHLIST",
        payload: product
    }
}