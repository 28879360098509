import styled from 'styled-components';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Lottie from 'react-lottie-player';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Ecom from '../images/ecom2.png'
import Ecom1 from '../images/ecom983 1.png'
import Polygon from '../images/Polygon.png';
import Glassdoor from '../images/glassdoor1.png';
import Wave from '../images/radio-animation.json';
import Chart from '../images/chart_animation.json';

const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 5rem;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    padding: 10rem;
  }
  
  @media (max-width: 767px) {
    display: none;
  }
`;

const ChartContainer = styled.div`
  height: 160px;
  width: 100%;
  max-width: 440px;
  background-color: white;
  box-shadow: 0 35px 35px rgba(0, 0, 0, 0.25);
  border-radius: 2rem;
  margin-Top:500px
`;

const WaveContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top:-100px;

`;

const Wave1 = styled.div`
height:'full';
width:'full';
position: relative;
margin-top:-80px

`;

const WaveText = styled.div`
  position: absolute;
  height: 400px;
  width: 350px;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 45%;
  word-break: break-all;
  font-size: 3rem;
`;

const DiscoverText = styled.div`
  position: absolute;
  height: 300px;
  width: 400px;
  margin-top: 60%;
`;

const StartTrialContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -40%; /* Adjust margin-top according to your design */
`;


const HiddenImageContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-left: 60px;
    img {
      width: 388px;
      height: 382px;
    }
  }
`;

const Graph = () => {
  return (
    <SectionContainer>
      <div className='flex-1'>
        <ChartContainer>
          <Lottie loop animationData={Chart} play />
        </ChartContainer>
      </div>
      <div className='flex-1 relative'>
        <WaveContainer>
          <Wave1>
            <Lottie loop animationData={Wave} play />
          </Wave1>
          <WaveText>
            Create the software your business needs
          </WaveText>
          <DiscoverText>
            Discover a platform that enables you to design, develop and manage your web site exactly the way you want.
          </DiscoverText>
          <StartTrialContainer>
            <TextField
              style={{ width: '400px' }}
              label=""
              InputProps={{
                style: { borderRadius: '20px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="outlined" style={{ backgroundColor: "#7E72BC", color: "#FFFFFF", textTransform: "none" }}>Start free trial</Button>
                  </InputAdornment>
                ),
              }}
            />
          </StartTrialContainer>
        </WaveContainer>
      </div>
      <HiddenImageContainer>
        <img src={Glassdoor} alt="Logo" onError={(e) => e.target.style.display = 'none'} />
      </HiddenImageContainer>
    </SectionContainer>
  );
};

export default Graph;
