import React from 'react';
import './selectBox.css'; // Create a CSS file for styling

const SelectBox = ({ selectedValue, onSelectChange }) => {
    const handleSelectChange = (event) => {
        onSelectChange(event.target.value);
    };

    return (
        <div className="select-container">
            <select
                className="custom-select"
                value={selectedValue}
                onChange={handleSelectChange}
            >
                <option value="option1">Active</option>
                <option value="option2">InActive</option>
            </select>
        </div>
    );
};

export default SelectBox;
