import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './addProduct.css';
import HomeNav from '../homeNav/homeNav';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageUploadButton from './ImageUpload';
import SelectBox from './selectBox';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
// import firebase from 'firebase/app';
// import 'firebase/storage';


const AddProductPage = () => {
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [productCategory, setProductCategory] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productQuantity, setProductQuantity] = useState('');
    const [selectedValue, setSelectedValue] = useState('option1'); // Default value
    const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
    const [loading, setLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertError, setAlertError] = useState(null)
    const [color, setColor] = useState('');
    const [thickness, setThickness] = useState('');
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [diameter, setDaimeter] = useState('');
    const [unit, setUnit] = useState('');
    const [imageUploadAlert, setImageUploadAlert] = useState(null);

    const response = JSON.parse(localStorage.getItem('userData'));
    const navigate = useNavigate()
    const handleFilesUploaded = (urls) => {
        // Update the state with the uploaded image URLs
        setUploadedImageUrls(urls);
        setAlertMessage('Image upload successful!');
    };

    const handleProductNameChange = (event) => {
        setProductName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleProductCategoryChange = (event) => {
        setProductCategory(event.target.value);
    };

    const handleProductPriceChange = (event) => {
        setProductPrice(event.target.value);
    };

    const handleProductQuantityChange = (event) => {
        setProductQuantity(event.target.value);
    };

    const handleSelectChange = (value) => {
        setSelectedValue(value);
    };
    const handleColor = (event) => {
        setColor(event.target.value);
    };
    const handleThickness = (event) => {
        setThickness(event.target.value);
    };
    const handleWidth = (event) => {
        setWidth(event.target.value);
    };
    const handleLength = (event) => {
        setLength(event.target.value);
    };
    const handleDiameter = (event) => {
        setDaimeter(event.target.value);
    };
    const handleUnit = (event) => {
        setUnit(event.target.value);
    };

    const handleSave = () => {
        if (!productName || !productCategory || !productPrice || !productQuantity || !selectedValue) {
            setAlertError('Please fill in all the required fields.')

            return;  // Stop further execution
        }
        setLoading(true);
        const productData = {
            id: new Date().getTime(),
            title: productName,
            description,
            status: selectedValue,
            category: productCategory,
            cost: productPrice,
            image: uploadedImageUrls,
            merchantId: response.payload.merchantId,  // Replace with the actual merchant ID
            quantity: productQuantity,
            color: color,
            thickness: thickness,
            width: width,
            length: length,
            diameter: diameter,
            unit: unit
        };

        fetch('https://us-central1-graphical-petal-399811.cloudfunctions.net/merchantServices/addProduct', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(productData)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Product saved successfully:', data);
                setLoading(false);
                setAlertMessage('This is a success alert — check it out!');
                navigate("/product")
                // You can handle success here
            })
            .catch(error => {
                console.error('Error saving product:', error);
                if (error && error.status === 400 && error.statusMsg === 'Missing required parameters.') {
                    // Handle the specific error case where status is 400 and status message is "Missing required parameters"
                    console.error('Missing required parameters.');
                    setAlertError('Missing required parameters.')
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error);
                }
            });
    };
    useEffect(() => {
        if (alertMessage) {
            const timer = setTimeout(() => {
                setAlertMessage(null);
            }, 3000);  // Hide the alert after 3 seconds

            return () => clearTimeout(timer);  // Clear the timeout if component unmounts
        }
        if (alertError) {
            const timer = setTimeout(() => {
                setAlertError(null);
            }, 3000);  // Hide the alert after 3 seconds

            return () => clearTimeout(timer);  // Clear the timeout if component unmounts
        }
    }, [alertMessage, alertError]);


    return (
        <div className="addProduct">
            <HomeNav />
            <div className='addProduct1'>
                {alertMessage && (
                    <Alert variant="outlined" severity="success">
                        {alertMessage}
                    </Alert>
                )}
                {alertError && (
                    <Alert variant="outlined" severity="error">
                        {alertError}
                    </Alert>
                )}
                {imageUploadAlert}
                <div onClick={() => navigate("/product")}>
                    <ArrowBackIcon />
                    Add Product
                </div>

                {loading && (
                    <div
                        style={{
                            zIndex: 9999,
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <CircularProgress color="secondary" />
                    </div>
                )}
                <div className='addProduct2'>
                    <div className='frame'>
                        <TextField className="custom-textfield"  // Apply the CSS class
                            label="Add Product Name"
                            variant="outlined"
                            multiline
                            rows={1}
                            value={productName}
                            onChange={handleProductNameChange}
                        />
                        <div>
                            <textarea
                                className="customTextInput"
                                rows="20" // Specify the number of visible rows
                                placeholder="Enter Description here"
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                        </div>
                    </div>
                    <div className='frame1'>
                        <SelectBox selectedValue={selectedValue} onSelectChange={handleSelectChange} className="custom-textfield1" />
                        <TextField className="custom-textfield1"  // Apply the CSS class
                            label="Product Category"
                            variant="outlined"
                            multiline
                            rows={1}
                            value={productCategory}
                            onChange={handleProductCategoryChange}
                        />
                        <TextField className="custom-textfield1"  // Apply the CSS class
                            label="Enter Your Product Price"
                            variant="outlined"
                            multiline
                            rows={1}
                            value={productPrice}
                            onChange={handleProductPriceChange} />
                        <TextField className="custom-textfield1"  // Apply the CSS class
                            label="Enter Quantity Products "
                            variant="outlined"
                            multiline
                            rows={1}
                            value={productQuantity}
                            onChange={handleProductQuantityChange}
                        />
                        <div className='frame5'>
                            <div className='column'>
                                <TextField
                                    className="custom-textfield2"
                                    label="Enter color"
                                    variant="outlined"
                                    multiline
                                    rows={1}
                                    value={color}
                                    onChange={handleColor}
                                />
                                <TextField
                                    className="custom-textfield2"
                                    label="Enter Thickness "
                                    variant="outlined"
                                    multiline
                                    rows={1}
                                    value={thickness}
                                    onChange={handleThickness}
                                />
                            </div>
                            <div className='column'>
                                <TextField
                                    className="custom-textfield2"
                                    label="Enter width "
                                    variant="outlined"
                                    multiline
                                    rows={1}
                                    value={width}
                                    onChange={handleWidth}
                                />
                                <TextField
                                    className="custom-textfield2"
                                    label="Enter length "
                                    variant="outlined"
                                    multiline
                                    rows={1}
                                    value={length}
                                    onChange={handleLength}
                                />
                            </div>
                            <div className='column'>
                                <TextField
                                    className="custom-textfield2"
                                    label="Enter diameter "
                                    variant="outlined"
                                    multiline
                                    rows={1}
                                    value={diameter}
                                    onChange={handleDiameter}
                                />
                                <TextField
                                    className="custom-textfield2"
                                    label="Enter unit "
                                    variant="outlined"
                                    multiline
                                    rows={1}
                                    value={unit}
                                    onChange={handleUnit}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className='addProduct3'>
                    <div className='frame2'>
                        <ImageUploadButton onFilesUploaded={handleFilesUploaded} setAlert={setImageUploadAlert} />
                    </div>
                    <div className='frame3'>
                        <Button variant="contained" style={{ backgroundColor: '#6E63A5', width: '120px' }} onClick={handleSave} >Save</Button>
                        <Button variant="contained" style={{ backgroundColor: '#6E63A5', width: '120px' }} >Discard</Button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddProductPage;
