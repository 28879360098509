import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCart, addWishlist, delWishlist } from "../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImageZoomWrapper from "./ImageZoomWrapper";
import { Link } from "react-router-dom";
import InnerImageZoom from 'react-inner-image-zoom'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Products = () => {
  const [data1, setData] = useState([]);
  const [filter, setFilter] = useState(data1);
  const [loading, setLoading] = useState(false);
  const [favoriteStates, setFavoriteStates] = useState(Array(data1.length).fill(false));

  let componentMounted = true;
  const { merchantId, id } = useParams()

  const [latestProducts, setLatestProducts] = useState([]);

  const handleToggleFavorite = (index, product) => {
    setFavoriteStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !prevStates[index];
      return newStates;
    });

    // Use the local state to determine the favorite status
    const isFavorite = favoriteStates[index];

    // Dispatch the add or remove action based on the isFavorite state
    if (!isFavorite) {
      // If it was not in the wishlist, add it
      dispatch(addWishlist(product));
    } else {
      // If it was already in the wishlist, remove it
      dispatch(delWishlist(product));
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const response = await fetch(`https://us-central1-graphical-petal-399811.cloudfunctions.net/merchantServices/getProductData/ByMerchantId/${merchantId}`);
      if (componentMounted) {
        const responseData = await response.json();
        setLatestProducts(responseData.payload);
        setFilter(responseData.payload);
        setLoading(false);
      }

      return () => {
        componentMounted = false;
      };
    };

    getProducts();
  }, [merchantId]);

  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product))
  };
  const addwishlist = (product) => {
    dispatch(addWishlist(product));
  };

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        {[...Array(6)].map((_, index) => (
          <div key={index} className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
            <Skeleton height={592} />
          </div>
        ))}
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = data1.filter((item) => item.category === cat);
    setFilter(updatedList);
  };

  const ShowProducts = () => {
    return (
      <>
        {latestProducts.map((product, index) => (
          <div id={product.id} key={product.id} className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
            <div className="card text-center h-100" key={product.id}>
              <InnerImageZoom src={product.image} width={'500px'} height={'500px'} />
              <div className="card-body">
                <h5 className="card-title">{product.title.substring(0, 12)}...</h5>
                <p className="card-text">{product.description.substring(0, 90)}...</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item lead">£ {product.cost}</li>
              </ul>
              <div className="card-body">
                <Link to={`/product/${merchantId}/` + product.id} className="btn btn-dark m-1">
                  Buy Now
                </Link>
                <button className="btn btn-dark m-1" onClick={() => addProduct(product)}>
                  Add to Cart
                </button>
                <div onClick={() => {
                  handleToggleFavorite(index, product);
                }}>
                  {favoriteStates[index] ? (
                    <FavoriteIcon color="secondary" />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="container my-3 py-3 bg-white">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">Latest Products</h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
          {loading ? <Loading /> : <ShowProducts />}
        </div>
      </div>
    </>
  );
};

export default Products;
