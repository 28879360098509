import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAnVPI0SObYY8UhlcfMtIjmBZqqh-VbplA",
    authDomain: "darlaston-768f5.firebaseapp.com",
    projectId: "darlaston-768f5",
    storageBucket: "darlaston-768f5.appspot.com",
    messagingSenderId: "318760048391",
    appId: "1:318760048391:web:45811c76038f1cc81217a2",
    measurementId: "G-SR9FFD40RC"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app); // Use getStorage to get the storage instance

export { storage, app as default };
