// DataContext.js
import React, { createContext, useState } from 'react';

const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [responseData, setResponseData] = useState(null);

    return (
        <DataContext.Provider value={{ responseData, setResponseData }}>
            {children}
        </DataContext.Provider>
    );
};

export { DataContext, DataProvider };
