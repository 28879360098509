// const whishList = []

// const handleWishlist = (state = whishList, action) => {
//     const product = action.payload
//     switch (action.type) {
//         case "ADDITEM":
//             // Check if product already in cart
//             const exist = state.find((x) => x.id === product.id)
//             if (exist) {
//                 // Increase the quantity
//                 return state.map((x) => x.id === product.id ? { ...x, qty: x.qty + 1 } : x)
//             }
//             else {
//                 return [...state, { ...product, qty: 1 }]
//             }
//             break;
//         case "DELITEM":
//             const exist2 = state.find((x) => x.id === product.id)
//             if (exist2.qty === 1) {
//                 return state.filter((x) => x.id !== exist2.id)
//             }
//             else {
//                 return state.map((x) => x.id === product.id ? { ...x, qty: x.qty - 1 } : x)
//             }
//             break;

//         default:
//             return state
//             break;
//     }
// }

// export default handleWishlist
const wishlist = [];

const handleWishlist = (state = wishlist, action) => {
    const product = action.payload;
    switch (action.type) {
        case "ADD_TO_WISHLIST":
            const exist = state.find((x) => x.id === product.id);
            if (exist) {
                // Increase the quantity
                return state.map((x) => x.id === product.id ? { ...x, qty: x.qty + 1 } : x);
            } else {
                return [...state, { ...product, qty: 1 }];
            }
        case "REMOVE_FROM_WISHLIST":
            const exist2 = state.find((x) => x.id === product.id);
            if (exist2.qty === 1) {
                return state.filter((x) => x.id !== exist2.id);
            } else {
                return state.map((x) => x.id === product.id ? { ...x, qty: x.qty - 1 } : x);
            }
        default:
            return state;
    }
};

export default handleWishlist;