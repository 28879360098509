import { Navbar, Main, Product, Footer } from "../components";
import { useParams } from 'react-router-dom';
function Home() {
  const { merchantId } = useParams();
  return (
    <div className="bg-[#F6F4E8]">
      <Navbar merchantId={merchantId} />
      <Main merchantId={merchantId} />
      <Product merchantId={merchantId} />
      <Footer merchantId={merchantId} />
    </div>
  )
}

export default Home