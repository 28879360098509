import React, { useState, useContext } from 'react';
import './login.css'; // Make sure to adjust the path based on your project structure
import Lottie from 'react-lottie-player';
import Business from '../images/business-analysis.json';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../home/DataContext';

const Login = () => {
    const navigate = useNavigate();
    const { setResponseData } = useContext(DataContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        if (!email || !password) {
            alert('Please fill in all details.');
            return;
        }

        // Your API endpoint
        const apiUrl = 'https://us-central1-graphical-petal-399811.cloudfunctions.net/authFunction/merchantLogin';

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                }),
            });
            const responseData = await response.json(); // Read response as JSON once

            if (response.ok) {
                if (responseData.status === 400 && responseData.statusMsg === 'Email/Password Incorrect') {
                    alert('Email and Password is Incorrect.');
                } else {
                    setResponseData(responseData);
                    localStorage.setItem('userData', JSON.stringify(responseData));
                    navigate('/Home');
                }
            } else {
                console.error('Login failed:', responseData);
            }
        } catch (error) {
            console.error('An error occurred while making the API call:', error);
        }
    };

    return (
        <div className="containermain">
            <div className="loginText">
                <div className='welcomeText'>
                    <div className='welcomeText1'>
                        Welcome back
                    </div>
                    <div className='welcomeText2'>Welcome back! Please enter your details</div>
                </div>
                <div>
                    <Box className="custom-box">
                        <TextField
                            fullWidth
                            label="Email"
                            id="email"
                            type='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Box>
                </div>
                <div>
                    <Box className="custom-box">
                        <TextField
                            fullWidth
                            label="Password"
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Box>
                    <div className='Forgot'>
                        Forgot password?
                    </div>
                </div>
                <div>
                    <Button
                        variant="contained"
                        className="signup"
                        style={{ backgroundColor: '#6E63A5' }}
                        onClick={handleLogin}
                    >
                        Login
                    </Button>
                    <div className='Signup' onClick={() => { navigate('/signup') }}>
                        Don't have an account yet? Sign Up
                    </div>
                </div>
            </div>
            <div className="loginImage">
                <Lottie loop animationData={Business} play />
            </div>
        </div>
    );
};

export default Login;
