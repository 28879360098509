import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useDispatch } from "react-redux";
import { addCart, addWishlist, delWishlist } from "../redux/action";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Footer, Navbar } from "../components";
import './product.css'
import InnerImageZoom from "react-inner-image-zoom";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Product = () => {
  const { merchantId, id } = useParams();
  const [product, setProduct] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [mainImage, setMainImage] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [latestProducts, setLatestProducts] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');

  const dispatch = useDispatch();  // Move this to the top level

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      setLoading2(true);

      try {
        const response = await fetch(
          `https://us-central1-graphical-petal-399811.cloudfunctions.net/merchantServices/getProductData/ByMerchantId/${merchantId}`
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        console.log("responseData", responseData);
        const dataPayload = responseData.payload;
        console.log("responseData1", dataPayload);

        // Convert the dataPayload object into an array of objects
        const dataArray = Object.values(dataPayload);

        // Filter the dataArray based on the id
        const filteredData = dataArray.filter(item => item.id.toString() === id);
        console.log('Filtered Data:', filteredData);
        console.log('id Data:', id);

        if (filteredData.length === 0) {
          console.log('No matching items found for the given id.');
        } else {
          console.log('Filtered data1:', filteredData[0].image.split(',')[0]);

          // Assuming you want to set the first matching item (you can adjust this logic)
          setProduct(filteredData[0]);
          setMainImage(filteredData[0].image)
          // Filter similar products based on category
          const category = filteredData[0].category;
          const similarCategoryProducts = dataArray.filter(item => item.category === category);
          console.log("category", similarCategoryProducts);
          setSimilarProducts(similarCategoryProducts);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
        setLoading2(false);
      }
    };

    getProduct();
  }, [id]);

  // Check if product is defined before trying to access its properties
  if (!product || !product.color) {
    return <div>No product data available</div>;
  }

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleToggleFavorite = () => {
    setIsFavorite((prevStates) => !prevStates);
    if (!isFavorite) {
      // If it was not in the wishlist, add it
      dispatch(addWishlist(product));
    } else {
      // If it was already in the wishlist, remove it
      dispatch(delWishlist(product));
    }
  };

  const changeMainImage = (imageSrc) => {
    setMainImage(imageSrc);
  };

  const addProduct = (product) => {
    console.log("addProduct", product)
    dispatch(addCart(product));
  };

  const Loading = () => {
    return (
      <>
        <div className="container my-5 py-2">
          <div className="row">
            <div className="col-md-6 py-3">
              <Skeleton className="h-96 w-96" />
            </div>
            <div className="col-md-6 py-5">
              <Skeleton className="h-8 w-52 mb-3" />
              <Skeleton className="h-24 mb-4" />
              <Skeleton className="h-10 w-20 mb-4" />
              <Skeleton className="h-12 w-44 mb-4" />
              <Skeleton className="h-32" />
              <Skeleton className="h-10 w-44 inline" />
              <Skeleton className="h-10 w-44 mx-3 inline" />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ShowProduct = () => {
    return (
      <>
        {product && product.image && (
          <div>
            <div style={{ marginTop: '10px', background: '#eee' }} className="container bootdey">
              <div className="col-md-12">
                <section className="panel">
                  <div className="panel-body">
                    <div className="col-md-6">
                      <div className="pro-img-details">
                        <InnerImageZoom src={mainImage} alt="" />
                      </div>
                      <div className="pro-img-list">
                        <a href="#" onClick={() => changeMainImage(product.image.split(',')[0])}>
                          <img src={mainImage} alt="" style={{ width: '115px', height: '100px' }} />
                        </a>
                        <a href="#" onClick={() => changeMainImage(product.image.split(',')[1])}>
                          <img src={product.image.split(',')[1]} alt="" style={{ width: '115px', height: '100px' }} />
                        </a>
                        <a href="#" onClick={() => changeMainImage(product.image.split(',')[2])}>
                          <img src={product.image.split(',')[2]} alt="" style={{ width: '115px', height: '100px' }} />
                        </a>
                        <a href="#" onClick={() => changeMainImage(product.image.split(',')[3])}>
                          <img src={product.image.split(',')[3]} alt="" style={{ width: '115px', height: '100px' }} />
                        </a>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <h4 className="pro-d-title">
                        <a href="#/" className="">
                          {product.title}
                        </a>
                      </h4>
                      <p>
                        {product.description}
                      </p>
                      <div className="product_meta">
                        <span className="posted_in"> <strong>Category:</strong> <a rel="tag" href="#/">{product.category}</a></span>
                      </div>
                      <div className="m-bot15"> <strong>Price : </strong> <span className="pro-price"> $300.00</span></div>
                      <div className="item-list">
                        <div className="form-group">
                          <label>Quantity</label>
                          <input type="quantity" placeholder="1" className="form-control quantity" readOnly />
                        </div>
                        <div className="form-group">
                          <label htmlFor="colorSelect">Color</label>
                          <select
                            id="colorSelect"
                            className="form-control"
                            value={selectedColor}
                            onChange={handleColorChange}
                          >
                            {product.color.split(',').map((color, index) => (
                              <option key={index} value={color.trim()}>
                                {color.trim()}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="item-list">
                          {product && product.diameter && (
                            <div className="form-group">
                              <label>diameter</label>
                              <input type="quantity" className="form-control quantity" value={product.diameter} readOnly />
                            </div>
                          )}
                          {product && product.length && (
                            <div className="form-group">
                              <label>length</label>
                              <input type="quantity" className="form-control quantity" value={product.length} readOnly />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="item-list">
                        {product && product.thickness && (
                          <div className="form-group">
                            <label>thickness</label>
                            <input type="quantity" className="form-control quantity" value={product.thickness} readOnly />
                          </div>
                        )}
                        {product && product.unit && (
                          <div className="form-group">
                            <label>unit</label>
                            <input type="quantity" className="form-control quantity" value={product.unit} readOnly />
                          </div>
                        )}
                        {product && product.width && (
                          <div className="form-group">
                            <label>width</label>
                            <input type="quantity" className="form-control quantity" value={product.width} readOnly />
                          </div>
                        )}
                      </div>
                      <p>
                        <button className="btn btn-round btn-danger" type="button" onClick={() => addProduct(product)}>
                          <i className="fa fa-shopping-cart"></i> Add to Cart
                        </button>
                      </p>
                      <div onClick={() => {
                        handleToggleFavorite(product);
                      }}>
                        {isFavorite ? (
                          <FavoriteIcon color="secondary" />
                        ) : (
                          <FavoriteBorderIcon />
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div >
        )}
      </>
    );
  };

  const Loading2 = () => {
    return (
      <>
        <div className="my-4 py-4">
          <div className="flex">
            <div className="mx-4">
              <Skeleton className="h-96 w-60" />
            </div>
            <div className="mx-4">
              <Skeleton className="h-96 w-60" />
            </div>
            <div className="mx-4">
              <Skeleton className="h-96 w-60" />
            </div>
            <div className="mx-4">
              <Skeleton className="h-96 w-60" />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ShowSimilarProduct = () => {
    return (
      <>
        <div className="py-4 my-4">
          <div className="flex">
            {similarProducts.map((item) => {
              return (
                <div key={item.id} className="card mx-4 text-center">
                  <img
                    src={item.image}
                    alt="Card"
                    className="card-img-top p-3 w-96 h-96"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {item.title.substring(0, 15)}...
                    </h5>
                  </div>
                  <div className="card-body">
                    <Link to={`/product/${merchantId}${item.id}`} className="btn btn-dark m-1">
                      Buy Now
                    </Link>

                    <button
                      className="btn btn-dark m-1"
                      onClick={() => addProduct(item)}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Navbar merchantId={merchantId} />
      <div className="container bg-[#F6F4E8]">
        <div className="row">
          <ShowProduct />
        </div>
        <div className="row my-5 py-5">
          <div className="hidden md:block">
            <h2 className="text-2xl">You may also Like</h2>
            <Marquee
              pauseOnHover={true}
              pauseOnClick={true}
              speed={50}
            >
              {loading2 ? <Loading2 /> : <ShowSimilarProduct />}
            </Marquee>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;
