import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Avatar } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './settings.css';
import Footer from '../footer/footer';
import HomeNav from '../homeNav/homeNav';
function SettingsForm() {
    const [formData, setFormData] = useState({
        storeBannerImage: '',
        storeTimings: '', // Initialize with an empty string
        storeLocation: '', // Initialize with an empty string
        owners: [
            {
                ownerName: '',
                ownerPhoneNumber: '',
                ownerEmail: '',
                ownerImage: null,
            },
        ],
    });

    const handleChange = (e, ownerIndex) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            const file = files[0];
            const imageUrl = URL.createObjectURL(file);
            setFormData((prevData) => ({
                ...prevData,
                owners: prevData.owners.map((owner, index) =>
                    index === ownerIndex ? { ...owner, [name]: imageUrl, ownerImageFile: file } : owner
                ),
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                owners: prevData.owners.map((owner, index) =>
                    index === ownerIndex ? { ...owner, [name]: value } : owner
                ),
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to a server or store in local state)
        console.log('Form data:', formData);
    };

    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.data;
    const merchantId = data?.merchantId;
    console.log(merchantId);

    const handleLoginClick = () => {
        navigate('/LoginCustomer'); // Navigate to the Login page
    };

    const handleCartClick = () => {
        navigate('/cart'); // Navigate to the CartPage
    };

    const handleRegisterClick = () => {
        navigate('/Register');
    };

    const addOwner = () => {
        setFormData((prevData) => ({
            ...prevData,
            owners: [
                ...prevData.owners,
                {
                    ownerName: '',
                    ownerPhoneNumber: '',
                    ownerEmail: '',
                    ownerImage: null,
                },
            ],
        }));
    };

    const removeOwner = (ownerIndex) => {
        setFormData((prevData) => {
            const updatedOwners = prevData.owners.filter((owner, index) => index !== ownerIndex);
            return { ...prevData, owners: updatedOwners };
        });
    };

    return (
        <div>
            <HomeNav />

            <div className='company'>
                <div
                    id="header"
                    onClick={() =>
                        navigate(`/Home`, {
                            state: { data: { merchantId } },
                        })
                    }
                >
                    <ArrowBackIcon />
                    <h2>Company</h2>
                </div>
            </div>

            <form className="settings" onSubmit={handleSubmit}>
                <label>
                    Store Banner Image :
                    <input
                        type="file"
                        name="storeBannerImage"
                        onChange={handleChange}
                    />
                </label><br />

                <label className='timings'>
                    Store Timings :
                    <input
                        type="text"
                        name="storeTimings"
                        onChange={handleChange}
                    />
                </label><br />

                <label className='location'>
                    Store Location :
                    <input
                        type="text"
                        name="storeLocation"
                        onChange={handleChange}
                    />
                </label>

                <h2>Owners:</h2>

                {formData.owners.map((owner, ownerIndex) => (
                    <div key={ownerIndex}>
                        <h3>Owner {ownerIndex + 1}</h3>

                        <label>
                            Owner's Image :
                            <input
                                type="file"
                                name="ownerImage"
                                onChange={(e) => handleChange(e, ownerIndex)}
                            />
                            {owner.ownerImage && (
                                <img
                                    src={owner.ownerImage}
                                    alt={`Owner ${ownerIndex + 1}`}
                                    style={{ width: '100px' }}
                                />
                            )}
                        </label><br />

                        <label className='name'>
                            Owner's Name :
                            <input
                                type="text"
                                name="ownerName"
                                value={owner.ownerName}
                                onChange={(e) => handleChange(e, ownerIndex)}
                            />
                        </label><br />

                        <label className='phonenumber'>
                            Owner's Phone Number :
                            <input
                                type="tel"
                                name="ownerPhoneNumber"
                                value={owner.ownerPhoneNumber}
                                onChange={(e) => handleChange(e, ownerIndex)}
                            />
                        </label><br />

                        <label className='email'>
                            Owner's Email :
                            <input
                                type="email"
                                name="ownerEmail"
                                value={owner.ownerEmail}
                                onChange={(e) => handleChange(e, ownerIndex)}
                            />
                        </label>
                        <br />

                        <button
                            className="removeOwnerButton"
                            onClick={() => removeOwner(ownerIndex)}
                        >
                            Remove Owner
                        </button>
                    </div>
                ))}

                <div>
                    <button className="owner" type="button" onClick={addOwner}>
                        Add Owner
                    </button>
                </div>

                <button className="submitbutton" type="submit">Save</button>
            </form>
            <Footer />
        </div>
    );
}

export default SettingsForm;