import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Landing from './screens/LandingPage/landing';
import Signup from './screens/signup/signup';
import Home from './screens/home/home';
import Login from './screens/Login/login';
import { DataProvider } from './screens/home/DataContext';
import OrdersPage from './screens/OrdersScreen/order';
import ProductsPage from './screens/ProductsPage/product';
import AddProductPage from './screens/ProductsPage/addProduct';
import AboutPage from './pages/AboutPage';
import CustomersPage from './pages/Home';
import AllProductsPage from './pages/Products';
import ContactPage from './pages/ContactPage';
import ProductDetails from './pages/Product';
import CustomerLogin from './pages/Login';
import CustomerSignup from './pages/Register';
import Cart from './pages/Cart';
import Product from './pages/Product';
import SettingsForm from './screens/settings/settings';
import WishList from './pages/wishList'
// Import your Redux store and Provider from 'react-redux'
import { Provider } from 'react-redux';
import store from './redux/store';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(isUserLoggedIn());

  function isUserLoggedIn() {
    const response = JSON.parse(localStorage.getItem('userData'));
    const auth = response?.authToken;
    return auth !== null && auth !== undefined;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setLoggedIn(isUserLoggedIn());
    }, 5000); // Check every 5 seconds, adjust as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <Provider store={store}>
      <DataProvider>
        <Router>
          <div className="app">
            <Routes>
              <Route
                path="/"
                element={isUserLoggedIn() ? <Navigate to="/Home" /> : <Landing />}
              />
              <Route path="/Landing" element={<Landing />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/orders" element={<OrdersPage />} />
              <Route path="/product" element={<ProductsPage />} />
              <Route path="/addProduct" element={<AddProductPage />} />
              <Route path="/SettingsPage" element={<SettingsForm />} />
              <Route
                path="/customerHome/:merchantId"
                element={<CustomersPage />}
              />
              <Route path="/about/:merchantId" element={<AboutPage />} />
              <Route path="/contact/:merchantId" element={<ContactPage />} />
              <Route path="/allProducts/:merchantId" element={<AllProductsPage />} />
              <Route path="/productBy/:id" element={<ProductDetails />} />
              <Route path="/CustomerLogin/:merchantId" element={<CustomerLogin />} />
              <Route path="/CustomerSignup/:merchantId" element={<CustomerSignup />} />
              <Route path="/cart/:merchantId" element={<Cart />} />
              <Route path="/wishList/:merchantId" element={<WishList />} />
              <Route path="/product/:merchantId/:id" element={<Product />} />
            </Routes>
          </div>
        </Router>
      </DataProvider>
    </Provider>
  );
}

export default App;
