import React, { useState, useEffect } from 'react';
import './product.css';
import HomeNav from '../homeNav/homeNav';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { v4 as uuidv4 } from 'uuid';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function createData(name, calories, fat, carbs, protein) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}
const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Title',
    },
    {
        id: 'fat',
        numeric: true,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'Cost',
    },
    {
        id: 'protein',
        numeric: true,
        disablePadding: false,
        label: 'Quantity',
    },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
    },
    {
        id: 'desc',
        numeric: true,
        disablePadding: false,
        label: 'Description',
    },
];
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected, value } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {value === 0 ? 'All Products' : (value === 1 ? 'Active Products' : 'Inactive Products')}
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}


EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,

};



function OrdersPage() {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [editableRowId, setEditableRowId] = useState(null);
    const [editedValues, setEditedValues] = useState({});
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertError, setAlertError] = useState(null)

    const startRow = page * rowsPerPage;
    const endRow = startRow + rowsPerPage;
    const visibleRows = rows.slice(startRow, endRow);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditClick = (productId) => {
        setEditableRowId(productId);
        // Initialize the edited values with the current row values
        setEditedValues({
            ...editedValues,
            [productId]: { ...rows.find((row) => row.id === productId) }
        });
    };


    async function updateProduct(productId, updatedData) {
        try {
            const { name, fat, carbs, protein, active, desc } = updatedData;

            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    title: name,
                    category: fat,
                    cost: carbs,
                    quantity: protein,
                    status: active,
                    description: desc,
                }),
            };

            const response = await fetch(
                `https://us-central1-graphical-petal-399811.cloudfunctions.net/merchantServices/update/product/${productId}`,
                requestOptions
            );

            if (!response.ok) {
                // Check for error status and handle accordingly
                const errorData = await response.json();
                throw new Error(`Error: ${response.status} - ${errorData.message}`);
            }

            const data = await response.json();
            console.log('Product updated successfully:', data);

            // Add any additional handling after a successful update
            setLoading(false);
            setAlertMessage('Product updated successfully!');
        } catch (error) {
            console.error('Error updating product:', error.message);
            // Handle error appropriately, e.g., show an error message to the user
        }
    }




    const handleSaveClick = (productId) => {
        // Call the update function with the edited values for the specific product ID
        if (editedValues[productId]) {
            updateProduct(productId, editedValues[productId]);
            setEditableRowId(null); // Reset editable row after saving
            <Alert severity="success">This is a success alert — check it out!</Alert>
        }
    };

    const handleCancelClick = (productId) => {
        // Reset editable row on cancel
        setEditableRowId(null);
    };
    const handleInputChange = (productId, fieldName, value) => {
        // Update the edited values when input changes for the specified product ID
        setEditedValues({
            ...editedValues,
            [productId]: {
                ...editedValues[productId],
                [fieldName]: value
            }
        });
    };
    const handleDelete = (productId) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: productId })  // Include the product ID in the request body
        };

        fetch(`https://us-central1-graphical-petal-399811.cloudfunctions.net/merchantServices/deleteProduct`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Product deleted successfully:', data);
                setLoading(false);
                setAlertMessage('Product deleted successfully!');

                // Handle success response as needed
            })
            .catch(error => {
                console.error('Error deleting product:', error);
                // Handle error appropriately
            });
    };



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };



    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const response = JSON.parse(localStorage.getItem('userData'));
    console.log("1", response.payload)

    let merchantId;

    if (response && response.payload) {
        merchantId = response.payload.merchantId
    } else {
        // Handle the case where response or response.payload is null
        merchantId = '';
    }

    useEffect(() => {
        const fetchAndSetProductData = async () => {
            try {
                const response = await fetch(`https://us-central1-graphical-petal-399811.cloudfunctions.net/merchantServices/getProductData/ByMerchantId/${merchantId}`);
                const data = await response.json();
                if (data && data.payload) {
                    setRows(data.payload.map(item => ({
                        id: item.id,
                        name: item.title,
                        fat: item.category,
                        carbs: item.cost,
                        protein: item.quantity,
                        active: item.status === 'option1' ? 'Active' : 'Inactive',  // Map 'option1' to 'Active', 'option2' to 'Inactive'
                        desc: item.description
                    })));
                    setTotalCount(data.payload.length);  // Set the total count
                }
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };


        // Fetch data initially
        fetchAndSetProductData();

        // Fetch data at regular intervals (every 5 seconds in this case)
        const intervalId = setInterval(() => {
            fetchAndSetProductData();
        }, 5000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (alertMessage) {
            const timer = setTimeout(() => {
                setAlertMessage(null);
            }, 3000);  // Hide the alert after 3 seconds

            return () => clearTimeout(timer);  // Clear the timeout if component unmounts
        }
    }, [alertMessage]);


    const [csvData, setCsvData] = useState(null);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const text = event.target.result;
                const jsonArray = csvToJSON(text);
                setCsvData(jsonArray);
            };
            reader.readAsText(file);
        }
    };

    const csvToJSON = (csv) => {
        const lines = csv.split('\n');
        const result = [];
        const headers = lines[0].split(',');

        for (let i = 1; i < lines.length; i++) {
            const obj = {};
            const currentLine = lines[i].split(',');

            for (let j = 0; j < headers.length; j++) {
                // Sanitize the key to remove non-alphanumeric characters and spaces
                const key = headers[j].replace(/[^a-zA-Z0-9]/g, '');
                obj[key] = currentLine[j];
            }
            result.push(obj);
            console.log("result", result)
        }

        return result;
    };

    const Id = new Date().getTime()
    const handleSave = () => {
        if (!csvData || !Array.isArray(csvData) || csvData.length === 0) {
            setAlertError('CSV data is not in the expected format or is empty.');
            return;
        }

        setLoading(true);

        const productsToSave = csvData.map((csvProduct) => {
            const productData = {
                id: uuidv4(),
                title: csvProduct.title || 'Untitled',
                description: csvProduct.description || '',
                status: csvProduct.status || 'active',
                category: csvProduct.category || 'uncategorized',
                cost: parseFloat(csvProduct.cost) || 0,
                image: csvProduct.image || '',
                merchantId: response.payload.merchantId, // Replace with the actual merchant ID
                quantity: parseInt(csvProduct.quantity) || 0,
            };

            return productData;
        });

        // Create a function to save products one by one
        const saveProduct = (index) => {
            if (index >= productsToSave.length) {
                setLoading(false);
                setAlertMessage('Products saved successfully!');
                navigate('/product');
                return;
            }

            const productToSave = productsToSave[index];
            console.log("productToSave", productToSave)
            fetch('https://us-central1-graphical-petal-399811.cloudfunctions.net/merchantServices/addProduct', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(productToSave),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('Product saved successfully:', data);
                    saveProduct(index + 1);
                })
                .catch((error) => {
                    console.error('Error saving product:', error);
                    // Handle error as needed
                });
        };

        saveProduct(0);
    };



    return (
        <div>
            <HomeNav />
            {alertMessage && (
                <Alert variant="outlined" severity="success">
                    {alertMessage}
                </Alert>
            )}
            {loading && (
                <div
                    style={{
                        zIndex: 9999,
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <CircularProgress color="secondary" />
                </div>
            )}
            <div className='product'>
                <div className='addProductText'>Products</div>
                <div className='addProductButton1' >
                    <Button variant="contained" style={{ backgroundColor: '#6E63A5', width: '200px', height: '50px' }} onClick={handleSave} className='button1'>+MultiPleProducts</Button>
                    <input type="file" accept=".csv" onChange={handleFileUpload} style={{ width: 200, height: 200, border: "1 solid black" }} />
                </div>
                <div className='button2' >
                    <Button variant="contained" style={{ backgroundColor: '#6E63A5', width: '200px' }} onClick={() => { navigate('/addProduct') }}>+SingleProduct</Button>
                </div>
            </div>

            <div className='box'>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="All" {...a11yProps(0)} />
                            <Tab label="Active" {...a11yProps(1)} />
                            <Tab label="InActive" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <EnhancedTableToolbar numSelected={selected.length} value={value} />
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {visibleRows.map((row) => {
                                                const isEditable = row.id === editableRowId;

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.id}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox color="primary" />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" padding="none">
                                                            {isEditable ? (
                                                                <TextField
                                                                    value={editedValues[row.id]?.name || ''}
                                                                    onChange={(e) => handleInputChange(row.id, 'name', e.target.value)}
                                                                />
                                                            ) : (
                                                                row.name
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {isEditable ? (
                                                                <TextField
                                                                    value={editedValues[row.id]?.fat || ''}
                                                                    onChange={(e) => handleInputChange(row.id, 'fat', e.target.value)}
                                                                />
                                                            ) : (
                                                                row.fat
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {isEditable ? (
                                                                <TextField
                                                                    value={editedValues[row.id]?.carbs || ''}
                                                                    onChange={(e) => handleInputChange(row.id, 'carbs', e.target.value)}
                                                                />
                                                            ) : (
                                                                row.carbs
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {isEditable ? (
                                                                <TextField
                                                                    value={editedValues[row.id]?.protein || ''}
                                                                    onChange={(e) => handleInputChange(row.id, 'protein', e.target.value)}
                                                                />
                                                            ) : (
                                                                row.protein
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {isEditable ? (
                                                                <select
                                                                    value={editedValues[row.id]?.active || ''}
                                                                    onChange={(e) => handleInputChange(row.id, 'active', e.target.value)}
                                                                >
                                                                    <option value="option1">Active</option>
                                                                    <option value="option2">InActive</option>
                                                                    {/* Add more MenuItem components for additional options */}
                                                                </select>
                                                            ) : (
                                                                row.active
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {isEditable ? (
                                                                <TextField
                                                                    value={editedValues[row.id]?.desc || ''}
                                                                    onChange={(e) => handleInputChange(row.id, 'desc', e.target.value)}
                                                                    sx={{ width: '100%' }}
                                                                    rows={20}
                                                                    multiline
                                                                />
                                                            ) : (
                                                                row.desc
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {isEditable ? (
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        handleSaveClick(row.id)
                                                                        setLoading(true);
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            ) : (
                                                                <EditNoteIcon onClick={() => {
                                                                    handleEditClick(row.id);
                                                                    // Start loading when edit is clicked
                                                                }} />
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <DeleteIcon onClick={() => {
                                                                handleDelete(row.id);
                                                                setLoading(true);
                                                            }} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={totalCount}  // Use the totalCount instead of rows.length
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25]}
                                />
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            />
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <EnhancedTableToolbar numSelected={selected.length} value={value} />
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {visibleRows
                                                .filter(row => row.active === 'Active') // Filter rows where active is "Active"
                                                .map((row) => {
                                                    const isEditable = row.id === editableRowId;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row.id}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox color="primary" />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                {isEditable ? (
                                                                    <TextField
                                                                        value={editedValues[row.id]?.name || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'name', e.target.value)}
                                                                    />
                                                                ) : (
                                                                    row.name
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <TextField
                                                                        value={editedValues[row.id]?.fat || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'fat', e.target.value)}
                                                                    />
                                                                ) : (
                                                                    row.fat
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <TextField
                                                                        value={editedValues[row.id]?.carbs || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'carbs', e.target.value)}
                                                                    />
                                                                ) : (
                                                                    row.carbs
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <TextField
                                                                        value={editedValues[row.id]?.protein || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'protein', e.target.value)}
                                                                    />
                                                                ) : (
                                                                    row.protein
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <select
                                                                        value={editedValues[row.id]?.active || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'active', e.target.value)}
                                                                    >
                                                                        <option value="option1">Active</option>
                                                                        <option value="option2">InActive</option>
                                                                        {/* Add more MenuItem components for additional options */}
                                                                    </select>
                                                                ) : (
                                                                    row.active
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <TextField
                                                                        value={editedValues[row.id]?.desc || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'desc', e.target.value)}
                                                                    />
                                                                ) : (
                                                                    row.desc
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleSaveClick(row.id)}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                ) : (
                                                                    <EditNoteIcon onClick={() => handleEditClick(row.id)} />
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <DeleteIcon onClick={() => handleDelete(row.id)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={totalCount}  // Use the totalCount instead of rows.length
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25]}
                                />
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            />
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <EnhancedTableToolbar numSelected={selected.length} value={value} />
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {visibleRows
                                                .filter(row => row.active === 'Inactive') // Filter rows where active is "Active"
                                                .map((row) => {
                                                    const isEditable = row.id === editableRowId;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row.id}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox color="primary" />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                {isEditable ? (
                                                                    <TextField
                                                                        value={editedValues[row.id]?.name || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'name', e.target.value)}
                                                                    />
                                                                ) : (
                                                                    row.name
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <TextField
                                                                        value={editedValues[row.id]?.fat || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'fat', e.target.value)}
                                                                    />
                                                                ) : (
                                                                    row.fat
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <TextField
                                                                        value={editedValues[row.id]?.carbs || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'carbs', e.target.value)}
                                                                    />
                                                                ) : (
                                                                    row.carbs
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <TextField
                                                                        value={editedValues[row.id]?.protein || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'protein', e.target.value)}
                                                                    />
                                                                ) : (
                                                                    row.protein
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <select
                                                                        value={editedValues[row.id]?.active || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'active', e.target.value)}
                                                                    >
                                                                        <option value="option1">Active</option>
                                                                        <option value="option2">InActive</option>
                                                                        {/* Add more MenuItem components for additional options */}
                                                                    </select>
                                                                ) : (
                                                                    row.active
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <TextField
                                                                        value={editedValues[row.id]?.desc || ''}
                                                                        onChange={(e) => handleInputChange(row.id, 'desc', e.target.value)}
                                                                    />
                                                                ) : (
                                                                    row.desc
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {isEditable ? (
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleSaveClick(row.id)}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                ) : (
                                                                    <EditNoteIcon onClick={() => handleEditClick(row.id)} />
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <DeleteIcon onClick={() => handleDelete(row.id)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={totalCount}  // Use the totalCount instead of rows.length
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25]}
                                />
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            />
                        </Box>
                    </CustomTabPanel>
                </Box>

            </div>
        </div>
    );
}

export default OrdersPage;