import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
const Navbar = ({ merchantId }) => {
    const state = useSelector(state => state.handleCart)
    const isAuthenticated = useSelector((state) => state.authReducer);
    const wishList = useSelector((state) => state.handleWishlist);
    const navigate = useNavigate();
    const [isFavorite, setIsFavorite] = useState(wishList.length > 0);

    useEffect(() => {
        setIsFavorite(wishList.length > 0);
    }, [wishList]);

    const handleToggleFavorite = () => {
        setIsFavorite((prevIsFavorite) => !prevIsFavorite);
        // You may also want to dispatch an action to update the wishlist in your Redux store
    };
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <nav className="py-3 top-0 bg-[#BACEC1]">
            <div className="container mx-auto flex items-center justify-between bg-transparent">
                <NavLink
                    to={`/customerHome/${merchantId}`}
                    className="text-xl font-bold text-black px-2"
                    style={{ textDecoration: 'none' }}
                >
                    React Ecommerce
                </NavLink>
                <button
                    className="lg:hidden block text-blue-500 hover:text-blue-600"
                    type="button"
                    onClick={toggleMobileMenu}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16m-7 6h7"
                        />
                    </svg>
                </button>

                <div className={`lg:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
                    <div>
                        <Button
                            id="fade-button"
                            aria-controls={open ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6h16M4 12h16m-7 6h7"
                                />
                            </svg>
                        </Button>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                            style={{ width: '100%' }}
                        >
                            {[
                                <MenuItem component={NavLink} to={`/customerHome/${merchantId}`} state={{ data: { merchantId: merchantId } }}>
                                    Home
                                </MenuItem>,
                                <MenuItem component={NavLink} to={`/allProducts/${merchantId}`} state={{ data: { merchantId: merchantId } }}>
                                    Products
                                </MenuItem>,
                                <MenuItem component={NavLink} to={`/about/${merchantId}`} state={{ data: { merchantId: merchantId } }}>
                                    About
                                </MenuItem>,
                                <MenuItem component={NavLink} to={`/contact/${merchantId}`} state={{ data: { merchantId: merchantId } }}>
                                    Contact
                                </MenuItem>,
                                <MenuItem>
                                    whishlist
                                </MenuItem>
                            ]}
                            {isAuthenticated ? (
                                [
                                    <MenuItem component={NavLink} to={`/cart/${merchantId}`} onClick={handleClose}>
                                        Cart
                                    </MenuItem>,
                                    <MenuItem component={NavLink} to={`/wishList/${merchantId}`} onClick={handleToggleFavorite}>
                                        {isFavorite ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}{wishList.length}
                                    </MenuItem>
                                ]
                            ) : (
                                [
                                    <MenuItem component={NavLink} to={`/CustomerLogin/${merchantId}`} onClick={handleClose}>
                                        Login
                                    </MenuItem>,
                                    <MenuItem component={NavLink} to={`/CustomerSignup/${merchantId}`} onClick={handleClose}>
                                        Register
                                    </MenuItem>
                                ]
                            )}
                        </Menu>
                    </div>
                </div>

                <div className="hidden lg:flex text-black space-x-4">
                    <NavLink to={`/customerHome/${merchantId}`} className="nav-link" state={{ data: { merchantId: merchantId } }}>
                        Home
                    </NavLink>
                    <NavLink to={`/allProducts/${merchantId}`} className="nav-link" state={{ data: { merchantId: merchantId } }}>
                        Products
                    </NavLink>
                    <NavLink to={`/about/${merchantId}`} className="nav-link" state={{ data: { merchantId: merchantId } }}>
                        About
                    </NavLink>
                    <NavLink to={`/contact/${merchantId}`} className="nav-link" state={{ data: { merchantId: merchantId } }}>
                        Contact
                    </NavLink>

                </div>
                <div className="hidden lg:flex text-black space-x-4">
                    {isAuthenticated ? (
                        [
                            <NavLink to={`/wishList/${merchantId}`}>
                                <div onClick={handleToggleFavorite}>
                                    {isFavorite ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}{wishList.length}
                                </div>
                            </NavLink>,
                            <NavLink to={`/cart/${merchantId}`} className="btn btn-outline-dark">
                                <i className="fas fa-cart-shopping mr-1"></i> Cart ({state.length})
                            </NavLink>
                            /* Add a logout button or functionality here */
                        ]
                    ) : (
                        [
                            <NavLink to={`/CustomerLogin/${merchantId}`} className="btn btn-outline-dark">
                                <i className="fas fa-sign-in-alt mr-1"></i> Login
                            </NavLink>,
                            <NavLink to={`/CustomerSignup/${merchantId}`} className="btn btn-outline-dark">
                                <i className="fas fa-user-plus mr-1"></i> Register
                            </NavLink>
                        ]
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
