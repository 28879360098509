import React, { useState } from 'react';
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const ImageUploadButton = ({ onFilesUploaded, setAlert }) => {
    const handleFileUpload = async (event) => {
        const files = event.target.files;

        // Check if exactly 4 files are selected
        if (files.length !== 4) {
            setAlert(<Alert severity="error">Please upload exactly 4 images!</Alert>);
            setTimeout(() => {
                setAlert(null);
            }, 5000);
            return;
        }

        const fileUploadPromises = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const img = new Image();

            const uploadPromise = new Promise((resolve, reject) => {
                img.onload = () => {
                    // Check image resolution (width and height)
                    if (img.width >= 1400 && img.height >= 1400) {
                        const storageRef = ref(storage, `images/${file.name}`);
                        const uploadTask = uploadBytesResumable(storageRef, file);

                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                // Progress tracking if needed
                            },
                            (error) => {
                                reject(error);
                            },
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    resolve(downloadURL);
                                });
                            }
                        );
                    } else {
                        // Show alert for images that don't meet resolution requirements
                        setAlert(
                            <Alert severity="error">
                                Please upload images with a resolution of at least 1400x1400 pixels!
                            </Alert>
                        );
                        reject('Image resolution not met.');
                        setTimeout(() => {
                            setAlert(null);
                        }, 5000);
                        return;
                    }
                };

                img.src = URL.createObjectURL(file);
            });

            fileUploadPromises.push(uploadPromise);
        }

        try {
            const downloadURLs = await Promise.all(fileUploadPromises);

            // Check if exactly 4 download URLs are obtained
            if (downloadURLs.length === 4) {
                onFilesUploaded(downloadURLs);
            }
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };


    return (
        <div className=' flex flex-col'>
            <input
                accept="image/*"
                multiple
                type="file"
                style={{ display: 'none' }}
                id="image-upload-button"
                onChange={handleFileUpload}
            />
            <label htmlFor="image-upload-button">
                <Button variant="outlined" component="span" style={{ backgroundColor: '#6E63A5', width: '300px', color: 'white' }}>
                    + Add Your Product Images
                </Button>
            </label>
            <span style={{ marginLeft: '8px', color: '#6E63A5' }}>Upload up to 4 images (1400x1400px)</span>

        </div>
    );
};

export default ImageUploadButton;
