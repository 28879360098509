import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Footer, Navbar } from "../components";
import { useDispatch, useSelector } from 'react-redux';
import { setAuthenticated } from '../redux/action';

const Login = () => {
  const { merchantId } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Access isAuthenticated from Redux store
  const handleLogin = async (e) => {
    e.preventDefault();

    // Prepare the request data
    const requestData = {
      email,
      password,
    };

    try {
      const response = await fetch(
        "https://us-central1-graphical-petal-399811.cloudfunctions.net/authFunction/customerLogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        // Handle successful login, e.g., redirect to a dashboard
        console.log("Login successful");
        dispatch(setAuthenticated(true)); // Update authentication status
        navigate(`/customerHome/${merchantId}`);
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <Navbar merchantId={merchantId} />
      <div className="container my-3 py-3">
        <h1 className="text-center">Login</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form onSubmit={handleLogin}>
              <div className="my-3">
                <label htmlFor="floatingInput">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="my-3">
                <label htmlFor="floatingPassword">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error && <p className="text-danger">{error}</p>}
              <div className="my-3">
                <p>
                  New Here?{" "}
                  <Link to={`/CustomerSignup/${merchantId}`} className="text-decoration-underline text-info">
                    Register
                  </Link>{" "}
                </p>
              </div>
              <div className="text-center">
                <button className="my-2 mx-auto btn btn-dark" type="submit">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
